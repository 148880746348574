// body
//     font-family: 'BPG Nateli', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// h1, h2, h3, h4, h5, h6
//     font-family: 'BPG Nateli Mtavruli', 'Helvetica Neue', Helvetica, Arial, sans-serif;
//     text-transform uppercase

.box-create-application
    label
        font-weight normal
        &:not(.control-label)
            width 100%
        small
            color #666
    h5
        font-weight bold
        
    .additional-checkboxes
        font-size small
        .icheckbox_square-blue
            zoom 0.75

    .icheckbox_square-blue, .iradio_square-blue
        margin-right 7px

form.dropzone
    border 1px solid #ccc
    border-radius 3px
    background #fafafa

.todo-list .file-icon::before
    border-top-color #f4f4f4
    border-right-color #f4f4f4

.todo-list
    .tools .if-done
        display none
    .done
        opacity 0.5
        .tools .if-done
            display inline
        .tools .if-not-done
            display none

hr.timeline-separator
    border-top 4px solid #ddd
    width 80%

.textarea.wysihtml5
    width 100%
    height 200px
    font-size 14px
    line-height 18px
    border 1px solid #ddd
    padding 10px

.holly-dollies
    display none

.nowrap
    white-space nowrap

.yeswrap
    white-space normal

.todo-list > li .label
    font-size 75%

span.spacing
    display inline-block
    width 50px

.dz-error
    opacity .5
.dz-error .dz-image
    border 3px solid rgb(200, 0, 0)
.dz-success .dz-image
    border 3px solid rgb(0, 200, 0)
    
.label-warning, .label-info
    color #000 !important
    font-weight normal !important
.label-pinkie
    background-color rgb(255, 143, 145)
